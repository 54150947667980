import SecureLS from "secure-ls"
import { navigate } from "gatsby"

let ls = typeof window !== 'undefined' ? new SecureLS({encodingType: 'aes'}) : {get: () => {}, set: () => {}, remove: () => {}};


export const getUser = () => {
  const user = {
    token: ls.get("rider_token"),
    userId: ls.get("rider_id"),
    email: ls.get("rider_email"),
    name: ls.get("rider_name"),
    phone: ls.get("rider_phone"),
    messenger: ls.get("rider_messenger"),
    isCompletedDocuments: ls.get("isCompletedDocuments"),
    documents: ls.get("documents") ? ls.get('documents'): [],
    transportationType: ls.get("transportationType"),
  }

  return user
}

export const isLoggedIn = () => {
  const user = getUser()

  return user.userId.length > 0 ? true : false
}

export const setUser = ({ token, user }, func = () => {}) => {

  ls.set("rider_token", token)
  ls.set("rider_id", user._id)
  ls.set("rider_email", user.email)
  ls.set("rider_name", user.name)
  ls.set("rider_phone", user.phone)
  ls.set("rider_messenger", user.messenger)
  ls.set("isCompletedDocuments", user.isCompletedDocuments)
  ls.set("documents", user.documents)
  ls.set("transportationType", user.transportationType)


  func()
}


export const updateDocuments  = (documents) => {
  ls.set('documents', documents)
}


export const logOut = () => {
  ls.remove("rider_token")
  ls.remove("rider_id")
  ls.remove("rider_email")
  ls.remove("rider_name")
  ls.remove("rider_phone")
  ls.remove("rider_messenger")
  ls.remove("isCompletedDocuments")
  ls.remove("documents")
  ls.remove("transportationType")
  ls.remove("approved")
  navigate("/")
}

export const setApprovalStatus = () => {
  ls.set("approved", true)
}
